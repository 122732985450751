.a-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

.a-icon-6 {
  height: 6px;
  width: 6px;
}

.a-icon-8 {
  height: 8px;
  width: 8px;
}

.a-icon-9 {
  height: 9px;
  width: 9px;
}

.a-icon-12 {
  height: 12px;
  width: 12px;
}

.a-icon-14 {
  height: 14px;
  width: 14px;
}

.a-icon-16 {
  height: 16px;
  width: 16px;
}

.a-icon-17 {
  height: 17px;
  width: 17px;
}

.a-icon-24 {
  height: 24px;
  width: 24px;
}

.a-icon-22 {
  height: 22px;
  width: 22px;
}

.a-icon-20 {
  height: 20px;
  width: 20px;
}

.a-icon-32 {
  height: 32px;
  width: 32px;
}

.a-icon-40 {
  height: 40px;
  width: 40px;
}

@media only screen and (max-width: 768px) {
  .a-icon-24 {
    height: 18px;
    width: 18px;
  }

  .a-icon-22 {
    height: 20px;
    width: 20px;
  }
}

.a-icon-logout {
  background-image: url('~assets/icons/ic_logout.svg');
}

.a-icon-profile {
  background-image: url('~assets/icons/ic_profile.svg');
}

.a-icon-profile_light {
  background-image: url('~assets/icons/ic_profile_light.svg');
}

.a-icon-history {
  background-image: url('~assets/icons/ic_history.svg');
}

.a-icon-account {
  background-image: url('~assets/icons/ic_account.svg');
}

.a-icon-account_light {
  background-image: url('~assets/icons/ic_accountLight.svg');
}

.a-icon-payment {
  background-image: url('~assets/icons/ic_payment.svg');
}

.a-icon-payment_light {
  background-image: url('~assets/icons/ic_paymentLight.svg');
}

.a-icon-creditCard {
  background-image: url('~assets/icons/ic_creditCard.svg');
}

.a-icon-creditCard_light {
  background-image: url('~assets/icons/ic_creditCardLight.svg');
}

.a-icon-history_light {
  background-image: url('~assets/icons/ic_history_light.svg');
}

.a-icon-logout_light {
  background-image: url('~assets/icons/ic_logoutLight.svg');
}

.a-icon-moon {
  background-image: url('~assets/icons/ic_moon.svg');
}

.a-icon-moon_light {
  background-image: url('~assets/icons/ic_moonLight.svg');
}