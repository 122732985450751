@layer tailwind {
  @tailwind base;
}

@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  block-size: 100%;
  inline-size: 100%;
  overflow: hidden;
}

:root {
	--transition: 0.25s;
	--spark: 1.8s;
}

/* html {
  container: root layout / size;
} */

#__next {
  height: 100%;
}

/* Don't zoom text input on iOS */
@media only screen and (hover: none) and (pointer: coarse) {
  input:focus,
  select:focus,
  textarea:focus {
    font-size: 16px !important;
  }
}

.show-mobile {
  display: none;
}

/* helpful hiding classes for mobile */
@media only screen and (max-width: 768px) {
  .hide-mobile {
    display: none;
  }

  .show-mobile {
    display: block;
  }

  .notifications-container {
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    bottom: unset;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.popover-markdown pre {
  overflow: auto;
  margin-top: 5px;
}

.popover-markdown p {
  margin-bottom: 5px;
}

.popover-markdown {
  font-size: 0.75em;
}

.popover-markdown *:first-child {
  margin-top: 0;
  padding-top: 0;
}

.popover-markdown *:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.markdown-content hr {
  border-width: 0;
  border-top: 4px solid #777;
  margin: 30px 0;
}

.markdown-content h2 {
  line-height: 1.1;
  margin-bottom: 0.25em;
}

.markdown-content h2 + p {
  margin-top: 0;
}

.markdown-content blockquote {
  margin: 20px 0;
  background: #222;
  padding: 30px;
  border-top: 4px solid #333;
}

.markdown-content blockquote *:last-child {
  margin-bottom: 0;
}

.markdown-content img {
  max-width: 100%;
}

.markdown-content a {
  color: #4dabf7;
  text-decoration: underline;
}

.mantine-Alert-body .markdown-content a {
  color: inherit;
}

.light .markdown-content blockquote {
  background: #eee;
  border-top: 4px solid #ddd;
}

img.load-error {
  opacity: 1;
  background: rgba(255, 00, 00, 0.35);
}

iframe {
  border: none;
}

.demo-25 {
  opacity: 0.65;
  stroke-width: 2px;
}

.demo-50 {
  opacity: 0.75;
  stroke-width: 5px;
}

.demo-75 {
  opacity: 0.85;
  stroke-width: 7px;
}

.demo-100 {
  opacity: 0.95;
  stroke-width: 10px;
}

.demo-25-animated,
.frame-decor:hover .demo-25 {
  animation: flicker-25 2.5s ease-in-out infinite alternate;
}

.demo-50-animated,
.frame-decor:hover .demo-50 {
  animation: flicker-50 2s ease-in-out infinite alternate;
}

.demo-75-animated,
.frame-decor:hover .demo-75 {
  animation: flicker-75 1.5s ease-in-out infinite alternate;
}

.demo-100-animated,
.frame-decor:hover .demo-100 {
  animation: flicker-100 1s ease-in-out infinite alternate;
}

@keyframes flicker-100 {
  0% {
    opacity: 0.95;
    stroke-width: 10px;
  }

  100% {
    opacity: 1;
    stroke-width: 20px;
  }
}

@keyframes flicker-75 {
  0% {
    opacity: 0.85;
    stroke-width: 7px;
  }

  100% {
    opacity: 0.9;
    stroke-width: 15px;
  }
}

@keyframes flicker-50 {
  0% {
    opacity: 0.75;
    stroke-width: 5px;
  }

  100% {
    opacity: 0.8;
    stroke-width: 10px;
  }
}

@keyframes flicker-25 {
  0% {
    opacity: 0.65;
    stroke-width: 2px;
  }

  100% {
    opacity: 0.7;
    stroke-width: 5px;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.frame-decor .footer {
  padding-bottom: 36px !important;
}

.frame-decor .footer-abs > * {
  bottom: 36px !important;
}

@keyframes overscroll-spin {
  from {
    transform: translateX(-50%) rotate(0deg);
  }

  to {
    transform: translateX(-50%) rotate(360deg);
  }
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 200% 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes button-highlight {
  0% {
    background-position: -300% 50%;
  }

  30% {
    background-position: 300% 50%;
  }

  100% {
    background-position: 300% 50%;
  }
}

/* Google Recapcha Hiding */
.grecaptcha-badge {
  visibility: hidden;
}

/* .card {
  @apply bg-white border border-gray-200 rounded-lg shadow dark:bg-zinc-800 dark:border-zinc-700;
} */

ul {
  @apply list-disc;
}

ol {
  @apply list-decimal;
}

@layer utilities {
  .shadow-topper {
    box-shadow: inset 0 2px theme('colors.gray.2');
  }

  .dark .shadow-topper {
    box-shadow: inset 0 2px theme('colors.dark.4');
  }

  .card {
    @apply rounded-md overflow-hidden relative bg-gray-0 shadow-md shadow-gray-4 border-gray-3 flex flex-col;
  }

  .dark .card {
    @apply bg-dark-6 shadow-dark-8 border-dark-4;
  }

  .vertical-gradient-dark {
    position: relative;
  }

  .vertical-gradient-dark:before {
    content: '';
    position: absolute;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

[data-type='strawPoll'] {
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
}

[data-type='strawPoll'] iframe {
  flex-grow: 1;
}

div[data-youtube-video] > iframe {
  aspect-ratio: 16 / 9;
  height: 100%;
  width: 100%;
  max-width: 640px;
  min-width: 200px;
}

.scroll-area {
  height: 100%;
  width: 100%;
  flex: 1;
  overflow-x: hidden;
  will-change: transform;
  position: relative;
  scrollbar-width: thin;
  padding-top: 0;
}
