.promotionCarousel {
  max-width: 1006px;
  margin: auto;
}
.promotionCarousel__item {
  padding: 0 8px;
}
.promotionCarousel__item .promotionCarousel__item-img {
  display: block;
}
.promotionCarousel__item .promotionCarousel__item-img > span {
  display: block;
  border-radius: 0.375rem;
  overflow: hidden;
  border: 1px solid #3572bd !important;
}
.promotionCarousel .promotionCarousel__item img {
  aspect-ratio: 1006/212;
}

.promotionCarousel .promotionCarousel__item-link {
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;
}

.promotionCarousel .promotionCarousel__item-link:hover {
  opacity: 0.7;
}

.promotionCarousel .promotionCarousel__dots {
  bottom: 10px;
  pointer-events: fill;
}

.promotionCarousel .promotionCarousel__dots .promotionCarousel__dot {
  position: absolute;
  background-color: #ffff;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.25s ease-in-out;
}

.promotionCarousel .promotionCarousel__dots li {
  margin: 0;
}

.promotionCarousel .promotionCarousel__dots li.slick-active .promotionCarousel__dot {
  width: 16px;
  border-radius: 30px;
  background-color: #3572bd;
}

@media only screen and (max-width: 768px) {
  .promotionCarousel .promotionCarousel__item img {
    aspect-ratio: 1006/412;
  }

  .promotionCarousel .promotionCarousel__dots {
    bottom: 0;
  }
}
